import api from './api'
import { API_PATH } from '../constant'

export const ApiListCategory = () => {
    let data = api.get(API_PATH + '/categories');
    return data;
}

export const ApiListRank = () => {
    let data = api.get(API_PATH + '/rank');
    return data;
}

export const ApiDetailCategory = (id: string, perPage: number = 20) => {
    let data = api.get(API_PATH + '/category/' + id, {
        data: {
            per_page: perPage
        }
    });

    return data;
}

export const ApiLoadMoreCategory = (url: string) => {
    let data = api.get(url);
    return data;
}

export const ApiListFavorite = () => {
    let data = api.get(API_PATH + '/song/list-favorite');
    return data;
}