import api from './api'
import {API_PATH} from "../constant";

export const ApiInfoHome = () => {
    let data = api.get(API_PATH + '/info/home');
    return data;
}

export const ApiInfoArtist = () => {
    return api.get(API_PATH + '/info/artist');
}

export const ApiInfoBanner = () => {
    return api.get(API_PATH + '/info/banner');
}

export const ApiInfoRank = () => {
    return api.get(API_PATH + '/info/rank');
}

export const ApiInfoLienkhuc = () => {
    return api.get(API_PATH + '/info/lienkhuc');
}

export const ApiSearchData = (query: string) => {
    return api.post(API_PATH + '/search', {
        query: query
    })
}

export const ApiLoadmoreSearchData = (link, query: string) => {
    return api.post(link, {
        query: query
    })
}
