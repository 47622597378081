import axios from 'axios'
import * as ls from 'local-storage'

let token = ls.get<string>('token');
if (typeof token == "string") {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
}

let msisdn = ls.get<string>('msisdn');
if (typeof msisdn == "string") {
    axios.defaults.headers.common['msisdn'] = msisdn;
}

axios.defaults.timeout = 30000;

axios.interceptors.response.use( (response) =>  {
    return response;
}, (error) => {
    // if (error.response.code == 401) {
    //     ls.clear();
    //     window.location.replace(window.location.origin);
    // }
    return Promise.reject(error);
});

export default axios
