import api from './api'
import { API_PATH } from '../constant'

export const ApiGetSong = (id: string, type: string = 'nm') => {
    let data = api.get(API_PATH + '/song/' + id, {
        params: {
            type: type
        }
    });
    return data
}

export const ApiAddFavorite = (id: string) => {
    let data = api.post(API_PATH + '/song/favorite', {
        song_id: id
    });
    return data
}

export const ApiRemoveFavorite = (id: string) => {
    let data = api.post(API_PATH + '/song/un-favorite', {
        song_id: id
    });
    return data
}

export const ApiLogUser = (song_id: string, song_time: number, song_total_time) => {
    if (song_total_time > 0) {
        let data = api.post(API_PATH + '/song/log', {
            song_id: song_id,
            song_time: song_time,
            song_total_time: song_total_time
        })
        return data
    }
}

export const ApiViewCount = (id: string) => {
    let data = api.get(API_PATH + '/song/count-view/' + id);
    return data
}

export const ApiLogDownload = (id: string) => {
    let data = api.get(API_PATH + '/download/' + id);
    return data;
}