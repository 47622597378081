import * as types from '../constant/category'
import {
    GetCategoryActionInterface,
    DetailCategoryActionInterface,
    LoadMoreCategoryActionInterface, GetRankResultInterface
} from './type/category'
import {
    ApiDetailCategory,
    ApiListCategory, ApiListFavorite,
    ApiLoadMoreCategory,
    ApiListRank
} from '../api/category'
import { Dispatch } from 'redux'
import {ApiRemoveFavorite} from "../api/song";

export const getCategorySuccess: GetCategoryActionInterface = (data: object[]) => {
    return {
        type: types.GET_CATEGORY,
        data: data
    }
}

export const getCategoryAction = () => {
    return (dispatch: Dispatch) => {
        ApiListCategory().then(function (response) {
            if(response.data.code == 200) {
                dispatch(getCategorySuccess(response.data.data))
            } else {
                dispatch(getCategorySuccess([]));
            }
        }).catch(function () {
            dispatch(getCategorySuccess([]));
        });
    }
}

export const getRankSuccess = (data: any) => {
    return {
        type: types.GET_RANK,
        rank: data
    }
}

export const getRankAction = () => {
    return (dispatch: Dispatch) => {
        ApiListRank().then(function (response) {
            if(response.data.code == 200) {
                dispatch(getRankSuccess(response.data.data))
            } else {
                dispatch(getRankSuccess([]));
            }
        }).catch(function () {
            dispatch(getRankSuccess([]));
        });
    }
}

export const detailCategorySuccess: DetailCategoryActionInterface = (detail: any) => {
    return {
        type: types.DETAIL_CATEGORY,
        detail: detail
    }
}

export const detailCategoryAction = (id: string, perPage: number = 20) => {
    return (dispatch: Dispatch) => {
        ApiDetailCategory(id, perPage).then(function (response) {
            if(response.data.code == 200) {
                dispatch(detailCategorySuccess(response.data.data))
            } else {
                dispatch(detailCategorySuccess([]));
            }
        }).catch(function () {
            dispatch(detailCategorySuccess([]));
        });
    }
}

export const loadMoreCategorySuccess: LoadMoreCategoryActionInterface = (loadmore: any) => {
    return {
        type: types.LOAD_MORE_CATEGORY,
        loadmore: loadmore
    }
}

export const loadMoreCategoryAction = (url: string) => {
    return (dispatch: Dispatch) => {
        ApiLoadMoreCategory(url).then(function (response) {
            if(response.data.code == 200) {
                dispatch(loadMoreCategorySuccess(response.data.data))
            } else {
                dispatch(loadMoreCategorySuccess([]));
            }
        }).catch(function () {
            dispatch(loadMoreCategorySuccess([]));
        })
    }
}

export const listFavoriteSuccess = (data: any) => {
    return {
        type: types.LIST_FAVORITE,
        favorite: data
    }
}

export const listFavoriteAction = () => {
    return (dispatch: Dispatch) => {
        ApiListFavorite().then(function (response) {
            if(response.data.code == 200) {
                dispatch(listFavoriteSuccess(response.data.data))
            } else {
                dispatch(listFavoriteSuccess([]));
            }
        }).catch(function () {
            dispatch(listFavoriteSuccess([]));
        });
    }
}

export const removeListFavoriteSuccess = (id: string) => {
    return {
        type: types.REMOVE_LIST_FAVORITE,
        song_id: id
    }
}

export const removeListFavoriteAction = (id: string) => {
    return (dispatch: Dispatch) => {
        dispatch(removeListFavoriteSuccess(id));
        ApiRemoveFavorite(id);
    }
}

export const loadmoreListFavoriteSuccess = (data: any) => {
    return {
        type: types.LOADMORE_LIST_FAVORITE,
        favorite: data
    }
}

export const loadmoreListFavoriteAction = (url: string) => {
    return (dispatch: Dispatch) => {
        ApiLoadMoreCategory(url).then(function (response) {
            if(response.data.code == 200) {
                dispatch(loadmoreListFavoriteSuccess(response.data.data))
            } else {
                dispatch(loadmoreListFavoriteSuccess([]));
            }
        }).catch(function () {
            dispatch(loadmoreListFavoriteSuccess([]));
        })
    }
}

export const resetCategoryAction = () => {
    return {
        type: types.RESET_CATEGORY
    }
}