import api from './api'
import { API_PATH } from '../constant'
import * as ls from "local-storage";

export const ApiPostLogin = (phone: string, password: string) => {
    let data = api.post(API_PATH + '/login', {
        phone: phone,
        password: password
    });
    return data
}

export const ApiPostLogout = () => {
    let data = api.post(API_PATH + '/logout');
    return data
}

export const ApiChangePass = (password: string, new_password: string, re_password: string) => {
    let token = ls.get<string>('token');
    let data = api.post(API_PATH + '/auth/change-pass', {
        password: password,
        new_password: new_password,
        re_password: re_password
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return data
}

export const ApiGetInfo = () => {
    let token = ls.get<string>('token');
    let data = api.get(API_PATH + '/auth/info', {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
    return data
}

export const ApiAutoLogin = (phone) => {
    let data = api.post(API_PATH + '/auto-login', {
        phone: phone as string
    })
    return data
}