import * as types from '../constant/song'
import {
    GetSongActionInterface,
    AutoPlaySongActionInterface
} from "./type/song";
import {
    ApiAddFavorite,
    ApiGetSong, ApiRemoveFavorite,
    ApiViewCount
} from "../api/song";
import { Dispatch } from 'redux'
import {ApiLoadMoreCategory} from "../api/category";

export const getSongSuccess: GetSongActionInterface = (data: any) => {
    return {
        type: types.GET_SONG,
        song: data
    }
}

export const autoPlaySong: AutoPlaySongActionInterface = (data: any) => {
    return {
        type: types.AUTO_PLAY,
        autoplay: data
    }
}

export const getSongAction = (id: string, type: string) => {
    return (dispatch:Dispatch) => {
        ApiGetSong(id, type).then(function (response) {
            if(response.data.code == 200) {
                dispatch(getSongSuccess(response.data.data))
            } else {
                dispatch(getSongSuccess([]));
            }
        }).catch(function () {
            dispatch(getSongSuccess([]));
        })
    }
}

export const addFavoriteSuccess = () => {
    return {
        type: types.ADD_FAVORITE
    }
}

export const addFavoriteAction = (id: string) => {
    return (dispatch:Dispatch) => {
        dispatch(addFavoriteSuccess());
        ApiAddFavorite(id)
    }
}

export const removeFavoriteSuccess = () => {
    return {
        type: types.REMOVE_FAVORITE
    }
}

export const removeFavoriteAction = (id: string) => {
    return (dispatch:Dispatch) => {
        dispatch(removeFavoriteSuccess());
        ApiRemoveFavorite(id)
    }
}

export const loadmoreRelateSuccess = (data: any) => {
    return {
        type: types.LOADMORE_RELATE,
        song: data
    }
}

export const loadmoreRelateAction = (url) => {
    return (dispatch: Dispatch) => {
        ApiLoadMoreCategory(url).then(function (response) {
            if(response.data.code == 200) {
                dispatch(loadmoreRelateSuccess(response.data.data))
            }
        })
    }
}

export const getViewCountSuccess = (count: any) => {
    return {
        type: types.GET_VIEWCOUNT,
        count: count
    }
}

export const getViewCountAction = (id) => {
    return (dispatch: Dispatch) => {
        ApiViewCount(id).then(function (response) {
            if(response.data.code == 200) {
                dispatch(getViewCountSuccess(response.data.data))
            }
        })
    }
}
