import * as types from '../constant/auth'
import {
    PostLoginActionInterface,
    PostLoginFailActionInterface,
    PostLogoutActionInterface,
    GetInfoActionInterface,
    ChangePassActionInterface,
    ChangePassFailActionInterface
} from "./type/auth";
import { Dispatch } from 'redux'
import {
    ApiPostLogin, ApiPostLogout,
    ApiGetInfo, ApiChangePass, ApiAutoLogin
} from "../api/auth";
import * as ls from "local-storage";

export const postLoginSuccess : PostLoginActionInterface = (data: any) => {
    ls.set<string>('token', data.token);
    return {
        type:  types.POST_LOGIN,
        token: data.token,
        data: data.data
    }
}

export const postLoginFail : PostLoginFailActionInterface = (data: any) => {
    return {
        type: types.LOGIN_FAIL,
        data: data
    }
}

export const postLoginAction = (phone: string, password: string) => {
    return (dispatch: Dispatch) => {
        ApiPostLogin(phone, password).then((response) => {
            if(response.data.code == 200) {
                dispatch(postLoginSuccess(response.data.data))
            } else {
                dispatch(postLoginFail(response.data.data));
            }
        }).catch((response) => {
            dispatch(postLoginFail(response));
        })
    }
}

export const postLogoutSuccessAction: PostLogoutActionInterface = () => {
    ls.clear();
    return {
        type: types.POST_LOGOUT
    }
}

export const postLogoutAction = () => {
    return (dispatch: Dispatch) => {
        ApiPostLogout().then((response) => {
            dispatch(postLogoutSuccessAction())
        }).catch(() => {
            dispatch(postLogoutSuccessAction())
        })
    }
}

export const getInfoSuccessAction: GetInfoActionInterface = (data: any) => {
    return {
        type: types.GET_INFO,
        data: data
    }
}

export const getInfoAction = () => {
    return (dispatch: Dispatch) => {
        ApiGetInfo().then((response) => {
            if(response.data.code == 200) {
                dispatch(getInfoSuccessAction(response.data.data));
            } else {
                dispatch(getInfoSuccessAction([]));
            }
        }).catch(() => {
            dispatch(getInfoSuccessAction([]));
        })
    }
}

export const changePassSuccessAction: ChangePassActionInterface = (data: any) => {
    return {
        type: types.CHANGE_PASS,
        data: data
    }
}

export const changePassFailAction: ChangePassFailActionInterface = (data: any) => {
    return {
        type: types.CHANGE_PASS_FAIL,
        data: data
    }
}

export const changePassAction = (password: string, new_password: string, re_password: string) => {
    return (dispatch: Dispatch) => {
        ApiChangePass(password, new_password, re_password).then((response) => {
            if(response.data.code == 200) {
                dispatch(changePassSuccessAction(response.data.data));
            } else {
                dispatch(changePassFailAction(response.data.data));
            }
        }).catch((response) => {
            dispatch(changePassFailAction(response.data.data));
        })
    }
}

export const resetErrorAction = () => {
    return {
        type: types.RESET_ERROR
    }
}

export const autoLoginSuccessAction = (data: any, is_app: boolean) => {
    ls.set<string>('token', data.token);
    if (!is_app) {
        ls.set<string>('msisdn', data.data.phone);
    }
    return {
        type:  types.AUTO_LOGIN,
        token: data.token,
        data: data.data
    }
}

export const autoLoginAction = (phone: string, is_app: boolean = false) => {
    return (dispatch: Dispatch) => {
        ApiAutoLogin(phone).then((response) => {
            if(response.data.code == 200) {
                dispatch(autoLoginSuccessAction(response.data.data, is_app));
            }
        })
    }
}
