import * as types from '../constant/package'
import {
    ApiGetPackage, ApiUserPackage
} from "../api/package";
import { Dispatch } from 'redux'

export const getPackageSuccess = (data: any) => {
    return {
        type: types.GET_PACKAGE,
        package: data
    }
}

export const getPackageAction = () => {
    return (dispatch:Dispatch) => {
        ApiGetPackage().then(function (response) {
            if(response.data.code == 200) {
                dispatch(getPackageSuccess(response.data.data))
            } else {
                dispatch(getPackageSuccess([]));
            }
        }).catch(function () {
            dispatch(getPackageSuccess([]));
        })
    }
}

export const userPackageSuccess = (data: any) => {
    return {
        type: types.USER_PACKAGE,
        user: data
    }
}

export const userPackageAction = () => {
    return (dispatch:Dispatch) => {
        ApiUserPackage().then(function (response) {
            if(response.data.code == 200) {
                dispatch(userPackageSuccess(response.data.data))
            } else {
                dispatch(userPackageSuccess([]));
            }
        }).catch(function () {
            dispatch(userPackageSuccess([]));
        })
    }
}
