import * as types from '../constant/artist'
import {
    ApiDetailArtist,
    ApiGetArtist,
    ApiGetLienkhuc
} from "../api/artist";
import { Dispatch } from 'redux'
import {ApiLoadMoreCategory} from "../api/category";

export const loadmoreLienkhucSuccess = (data: any) => {
    return {
        type: types.LOADMORE_LIENKHUC,
        lienkhuc: data
    }
}

export const loadmoreLienkhucAction = (url: any) => {
    return (dispatch: Dispatch) => {
        ApiLoadMoreCategory(url).then(function (response) {
            if(response.data.code == 200) {
                dispatch(loadmoreLienkhucSuccess(response.data.data))
            }
        })
    }
}

export const getLienkhucSuccess = (data: any) => {
    return {
        type: types.LIST_LIENKHUC,
        lienkhuc: data
    }
}

export const getLienkhucAction = () => {
    return (dispatch: Dispatch) => {
        ApiGetLienkhuc().then(function (response) {
            if(response.data.code == 200) {
                dispatch(getLienkhucSuccess(response.data.data))
            } else {
                dispatch(getLienkhucSuccess([]));
            }
        }).catch(function () {
            dispatch(getLienkhucSuccess([]));
        });
    }
}

export const getArtistSuccess = (data: any) => {
    return {
        type: types.GET_ARTIST,
        artist: data
    }
}

export const getArtistAction = () => {
    return (dispatch: Dispatch) => {
        ApiGetArtist().then(function (response) {
            if(response.data.code == 200) {
                dispatch(getArtistSuccess(response.data.data))
            } else {
                dispatch(getArtistSuccess([]));
            }
        }).catch(function () {
            dispatch(getArtistSuccess([]));
        });
    }
}

export const loadmoreArtistSuccess = (data: any) => {
    return {
        type: types.LOADMORE_ARTIST,
        loadmore: data
    }
}

export const loadmoreArtistAction = (url) => {
    return (dispatch: Dispatch) => {
        ApiLoadMoreCategory(url).then(function (response) {
            if(response.data.code == 200) {
                dispatch(loadmoreArtistSuccess(response.data.data))
            }
        })
    }
}

export const detailArtistSuccess = (data: any) => {
    return {
        type: types.DETAIL_ARTIST,
        detail: data
    }
}

export const detailArtistAction = (id: string) => {
    return (dispatch: Dispatch) => {
        ApiDetailArtist(id).then(function (response) {
            if(response.data.code == 200) {
                dispatch(detailArtistSuccess(response.data.data))
            }
        })
    }
}

export const loadmoreDetailSuccess = (data: any) => {
    return {
        type: types.LOADMORE_DETAIL,
        detail: data
    }
}

export const loadmoreDetailAction = (url) => {
    return (dispatch: Dispatch) => {
        ApiLoadMoreCategory(url).then(function (response) {
            if(response.data.code == 200) {
                dispatch(loadmoreDetailSuccess(response.data.data))
            }
        })
    }
}

export const resetArtistAction = () => {
    return {
        type: types.RESET_ARTIST
    }
}
