import api from './api'
import { API_PATH } from '../constant'

export const ApiGetArtist = () => {
    let data = api.get(API_PATH + '/artist');
    return data
}

export const ApiDetailArtist = (id: string) => {
    let data = api.get(API_PATH + '/artist/' + id);
    return data
}

export const ApiGetLienkhuc = () => {
    let data = api.get(API_PATH + '/lienkhuc');
    return data;
}
