import {
    InfoHomeActionInterface,
    InfoArtistActionInterface, InfoLienkhucResultInterface
} from "./type/home";
import * as types from "../constant/home";
import {Dispatch} from "redux";
import {ApiInfoHome, ApiInfoArtist, ApiInfoBanner, ApiSearchData, ApiInfoRank,
    ApiLoadmoreSearchData, ApiInfoLienkhuc
} from "../api/home";
import {LoadMoreCategoryActionInterface} from "./type/category";
import {ApiLoadMoreCategory} from "../api/category";

export const infoLienkhucSuccess = (data: object[]) => {
    return {
        type: types.INFO_LIENKHUC,
        lienkhuc: data
    }
}

export const infoLienkhucAction = () => {
    return (dispatch: Dispatch) => {
        ApiInfoLienkhuc().then(function (response) {
            if(response.data.code == 200) {
                dispatch(infoLienkhucSuccess(response.data.data))
            } else {
                dispatch(infoLienkhucSuccess([]));
            }
        }).catch(function () {
            dispatch(infoLienkhucSuccess([]));
        });
    }
}

export const infoHomeSuccess: InfoHomeActionInterface = (data: object[]) => {
    return {
        type: types.INFO_HOME,
        data: data
    }
}

export const infoHomeAction = () => {
    return (dispatch: Dispatch) => {
        ApiInfoHome().then(function (response) {
            if(response.data.code == 200) {
                dispatch(infoHomeSuccess(response.data.data))
            } else {
                dispatch(infoHomeSuccess([]));
            }
        }).catch(function () {
            dispatch(infoHomeSuccess([]));
        });
    }
}

export const infoArtistSuccess: InfoArtistActionInterface = (artist: object[]) => {
    return {
        type: types.INFO_ARTIST,
        artist: artist
    }
}

export const infoArtistAction = () => {
    return (dispatch: Dispatch) => {
        ApiInfoArtist().then((response) => {
            if(response.data.code == 200) {
                dispatch(infoArtistSuccess(response.data.data))
            } else {
                dispatch(infoArtistSuccess([]))
            }
        }).catch(() => {
            dispatch(infoArtistSuccess([]))
        })
    }
}

export const infoBannerSuccess = (banner: any) => {
    return {
        type: types.INFO_BANNER,
        banner: banner
    }
}

export const infoBannerAction = () => {
    return (dispatch: Dispatch) => {
        ApiInfoBanner().then((response) => {
            if(response.data.code == 200) {
                dispatch(infoBannerSuccess(response.data.data))
            } else {
                dispatch(infoBannerSuccess([]))
            }
        }).catch(() => {
            dispatch(infoBannerSuccess([]))
        })
    }
}

export const infoRankSuccess = (rank: any) => {
    return {
        type: types.INFO_RANK,
        rank: rank
    }
};

export const infoRankAction = () => {
    return (dispatch: Dispatch) => {
        ApiInfoRank().then((response) => {
            if(response.data.code == 200) {
                dispatch(infoRankSuccess(response.data.data))
            } else {
                dispatch(infoRankSuccess([]))
            }
        }).catch(() => {
            dispatch(infoRankSuccess([]))
        })
    }
};

export const searchDataSuccess = (data: any) => {
    return {
        type: types.SEARCH_DATA,
        search: data
    }
}

export const searchDataAction = (query: string) => {
    return (dispatch: Dispatch) => {
        ApiSearchData(query).then((response) => {
            if(response.data.code == 200) {
                dispatch(searchDataSuccess(response.data.data))
            } else {
                dispatch(searchDataSuccess([]))
            }
        }).catch(() => {
            dispatch(searchDataSuccess([]))
        })
    }
}

export const loadMoreSearchSuccess = (search: any) => {
    return {
        type: types.LOADMORE_SEARCH,
        search: search
    }
}

export const loadMoreSearchAction = (url: string, query: string) => {
    return (dispatch: Dispatch) => {
        ApiLoadmoreSearchData(url, query).then(function (response) {
            if(response.data.code == 200) {
                dispatch(loadMoreSearchSuccess(response.data.data))
            } else {
                dispatch(loadMoreSearchSuccess([]));
            }
        }).catch(function () {
            dispatch(loadMoreSearchSuccess([]));
        })
    }
}
