import api from './api'
import { API_PATH } from '../constant'

export const ApiGetPackage = () => {
    let data = api.get(API_PATH + '/package');
    return data
}

export const ApiUserPackage = () => {
    let data = api.get(API_PATH + '/package/me');
    return data
}